import { useEffect, useState } from 'react';
import './CommReply.css';
import axiosInstance from '../axiosInstance';
import LoginPzModal from '../Login/LoginPzModalComponent/LoginPzModal';
import { faThumbsUp as likedIcon } from '@fortawesome/free-solid-svg-icons';
import { faThumbsUp as unLikedIcon } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const CommReply = ({ isAuth, userInfo, id, setUpdateReplyCnt }) => {
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [postReply, setPostReply] = useState({
    content: ''
  });
  const [getReply, setGetReply] = useState([]);
  const [update, setUpdate] = useState(false);

  const changeHandler = (e) => {
    const { value } = e.target;

    if (update) {
      setGetReply(getReply.map(reply =>
        reply.rno === update ? { ...reply, content: value } : reply
      ));
    } else {
      setPostReply({
        ...postReply,
        content: value
      });
    }
  }

  useEffect(() => {
    axiosInstance.get(`/commReply/${id}/list`)
      .then((response) => {
        setGetReply(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [id]);

  // 댓글 리스트 가져오기
  useEffect(() => {
    const fetchData = async () => {
      try {

        let commReplyDB = await axiosInstance.get(`/commReply/${id}/list`);
        let replyList = commReplyDB.data;

        // 이미지 URL을 가져오는 비동기 함수
        const fetchMemberImage = async (memberList) => {
          if (memberList.member && memberList.member.memberImage) {
            try {
              const imageResponse = await axiosInstance.get(`/userProfilePhoto/${memberList.member.id}`, {
                responseType: 'blob',
              });
              const imageUrl = URL.createObjectURL(imageResponse.data);
              return { ...memberList, memberImage: imageUrl };
            } catch (error) {
              console.log(error);
              return memberList;
            }
          } else {
            return { ...memberList, memberImage: 'https://raw.githubusercontent.com/Jella-o312/modo-image/main/etc/userImgNone.svg' };
          }
        };


        const updatedMemberList = await Promise.all(replyList.map(fetchMemberImage));
        setGetReply(updatedMemberList);

      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, [id, isUpdate]);



  const handleReplySubmit = () => {
    if (isAuth) {
      if (!postReply.content.trim()) {
        alert("댓글을 작성해주세요.");
      }
      else {
        const updateCommReply = { ...postReply, member: { username: userInfo.username } }
        axiosInstance.post(`/commReply/${id}`, updateCommReply)
          .then((response) => {
            alert(response.data);
            setPostReply({ ...postReply, content: '' });
            fetchNewReply(); // 댓글 추가 후 업데이트
            setUpdateReplyCnt(true);
            setIsUpdate(!isUpdate);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    } else {
      setShowLoginModal(true);
    }
  };

  const fetchNewReply = () => {
    axiosInstance.get(`/commReply/${id}/list`)
      .then((response) => {
        setGetReply(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const commDetailReplyHandler = (e, rno) => {
    let menu = e.target.textContent;

    switch (menu) {
      case "수정":
        setUpdate(rno);
        break;

      case "취소":
        setUpdate(false);
        fetchNewReply();
        break;

      case "수정완료":
        const updatedReply = getReply.find(reply => reply.rno === rno);
        if (!updatedReply || !updatedReply.content.trim()) {
          alert('수정할 내용을 입력해주세요.');
          return;
        }
        axiosInstance.put(`/commReply_update/${rno}`, { content: updatedReply.content })
          .then((response) => {
            alert(response.data);
            setUpdate(false);
            setIsUpdate(!isUpdate);
            fetchNewReply(); // 수정 후 댓글 목록 업데이트
          })
          .catch((error) => {
            console.log(error);
          });
        break;

      case "삭제":
        const deleteReply = window.confirm("정말 삭제하시겠습니까?");
        if (deleteReply) {
          axiosInstance.delete(`/commReply/${rno}`)
            .then((response) => {
              alert(response.data);
              setIsUpdate(!isUpdate);
              fetchNewReply(); // 댓글 삭제 후 목록 업데이트
            })
            .catch((error) => {
              console.log(error);
            });
        }
        break;

      default: break;
    }
  }

  const handleLikeClick = (rno) => {
    const isLiked = getReply.find(reply => reply.rno === rno).likedReply.includes(userInfo.id);
    const url = isLiked
      ? `/unlike/${rno}`
      : `/like/${rno}`;

    axiosInstance.post(url, userInfo.id)
      .then(response => {
        if (response.status === 200) {
          const updatedReplies = getReply.map(reply =>
            reply.rno === rno ? {
              ...reply,
              likedReply: isLiked
                ? reply.likedReply.filter(id => id !== userInfo.id)
                : [...reply.likedReply, userInfo.id]
            } : reply
          );
          setGetReply(updatedReplies);
        } else {
          console.log('error');
        }
      })
      .catch(error => {
        console.error('Error:', error);
      });
  };

  return (
    <div className='CommReply'>
      <div className='postReply'>
        <div className='replyTittle'>댓글</div>
        <div className='writeReply'>
          <textarea
            className='replyInput'
            value={postReply.content}
            onChange={changeHandler}
          />
          <button className='replyBtn' onClick={handleReplySubmit}>등록</button>
        </div>
      </div>
      <div className='getReplyBox'>
        {
          getReply.map((reply, i) => (
            <div className='getReply' key={i}>
              <div className='getReply-leftBox'>
                <div className='nickName-date'>
                  <img src={reply.memberImage} alt='프로필이미지' style={{borderRadius: '5rem', width:'auto', aspectRatio: '1/1'}} />
                  <div className='nickName'>{reply.member.nickname}</div>
                  <div className='date'>| {reply.createDate}</div>
                </div>
                {
                  update === reply.rno ? (
                    <textarea
                      defaultValue={reply.content}
                      className='Content'
                      style={{ width: "100%", outlineColor: "#8F7BE0" }}
                      onChange={changeHandler}
                    />
                  ) : (
                    <div className='Content'><pre style={{wordWrap: 'break-word', whiteSpace: 'pre-wrap'}}>{reply.content}</pre></div>
                  )
                }
                <div className='reply-update-delete'>
                  {userInfo.nickname === reply.member.nickname ? (
                    update === reply.rno ? (
                      <>
                        <button className='delete' onClick={(e) => commDetailReplyHandler(e, reply.rno)}>수정완료</button>
                        <button className='update' onClick={(e) => commDetailReplyHandler(e)}>취소</button>
                      </>
                    ) : (
                      <>
                        <button className='update' onClick={(e) => commDetailReplyHandler(e, reply.rno)}>수정</button>
                        <button className='delete' onClick={(e) => commDetailReplyHandler(e, reply.rno)}>삭제</button>
                      </>
                    )
                  ) : (
                    ''
                  )}
                </div>
              </div>
              <div>
                <FontAwesomeIcon
                  icon={reply.likedReply.includes(userInfo.id) ? likedIcon : unLikedIcon}
                  size="lg"
                  style={{ color: reply.likedReply.includes(userInfo.id) ? '#8F7BE0' : 'gray', cursor: 'pointer' }}
                  onClick={() => handleLikeClick(reply.rno)}
                />
                <div className='likeCnt'>{reply.liked ? reply.likedReply.length + 1 : reply.likedReply.length}</div>
              </div>
            </div>
          ))}
      </div>

      <LoginPzModal showLoginModal={showLoginModal} setShowLoginModal={setShowLoginModal} />
    </div>
  );
}

export default CommReply;
