import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.css';
import Modal from 'react-modal';
import ScrollToTop from './ScrollTop';

const root = ReactDOM.createRoot(document.getElementById('root'));
Modal.setAppElement('#root'); 
root.render(
  <BrowserRouter>
    <ScrollToTop/>
    <App />

  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(//console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
