let faqMockData = [
  {
    id: 12,
    category: "모임",
    title: "모임을 만들려면 어떻게 해야 하나요?",
    content: `
    모임을 만들기 위해서는 상단 메뉴에서 소모임 페이지로 이동한 후 하단 '+' 버튼을 클릭한 후 필요한 정보를 입력하고 저장하면 됩니다.
    대표 이미지, 모임 이름, 지역, 카테고리를 입력해야 하며, 추가로 모임에 대한 간단한 설명을 작성할 수 있습니다.
    모든 정보를 입력한 후 '저장' 버튼을 클릭하면 모임이 생성되고, 다른 사용자들이 해당 모임에 참가 신청을 할 수 있게 됩니다.
    `,
    createDate: "2024-06-01",
    adminName: "관리자"
  },
  {
    id: 11,
    category: "커뮤니티",
    title: "커뮤니티에 글을 작성하려면 어떻게 해야 하나요?",
    content: `
    커뮤니티에 글을 작성하려면 커뮤니티 페이지로 이동한 후 하단 '+' 버튼을 클릭하고 내용을 입력한 후 게시하면 됩니다.
    글 제목과 카테고리, 본문 내용을 입력할 수 있으며, 필요한 경우 사진이나 링크를 첨부할 수도 있습니다.
    작성한 글은 커뮤니티 회원들이 볼 수 있으며, 댓글을 통해 소통할 수 있습니다.
    `,
    createDate: "2024-06-02",
    adminName: "관리자"
  },
  {
    id: 10,
    category: "회원정보",
    title: "비밀번호를 잊어버렸어요. 어떻게 해야 하나요?",
    content: `
    비밀번호를 잊어버린 경우 로그인 페이지에서 '비밀번호 찾기'를 클릭하고 이메일 주소를 입력하면 비밀번호 재설정 할 수 있습니다.
    또는 고객센터에 문의하여 도움을 받을 수 있습니다.
    `,
    createDate: "2024-06-03",
    adminName: "관리자"
  },
  {
    id: 9,
    category: "기타",
    title: "사이트 이용 중 오류가 발생했어요. 어떻게 해야 하나요?",
    content: `
    사이트 이용 중 오류가 발생하면 고객센터로 문의하거나 '문의하기' 페이지에서 오류 내용을 작성하여 보내주시면 신속하게 처리해 드리겠습니다.
    오류 내용을 상세히 작성해 주시면 문제 해결에 큰 도움이 됩니다.
    특히, 오류 발생 시점과 상황을 구체적으로 설명해 주시면 더욱 정확한 지원이 가능합니다.
    `,
    createDate: "2024-06-04",
    adminName: "관리자"
  },
  {
    id: 8,
    category: "모임",
    title: "모임에 참가하려면 어떻게 해야 하나요?",
    content: `
    참가하고 싶은 모임 페이지로 이동한 후 '참가하기' 버튼을 클릭하면 모임에 참가할 수 있습니다.

    모임 리더와의 원활한 소통을 위해 모임 관련 공지사항을 자주 확인하시기 바랍니다.
    `,
    createDate: "2024-06-05",
    adminName: "관리자"
  },
  {
    id: 7,
    category: "기타",
    title: "탈퇴한 계정을 복구할 수 있나요?",
    content: `
    탈퇴한 계정은 복구할 수 없습니다. 
    새로 가입해 주시기 바랍니다.
    계정 탈퇴와 관련하여 궁금한 사항이 있으시면 고객센터로 문의해 주세요.
    `,
    createDate: "2024-06-08",
    adminName: "관리자"
  },
  {
    id: 6,
    category: "모임",
    title: "모임 일정은 어떻게 확인하나요?",
    content: `
    모임 일정은 모임 페이지에서 '게시판' 탭을 클릭하여 확인할 수 있습니다.
    일정은 모임 리더가 관리하며, 변경 사항이 있을 경우 참가자들에게 공지됩니다.
    일정에 대한 자세한 내용은 모임 주최자에게 문의해 주세요.
    `,
    createDate: "2024-06-09",
    adminName: "관리자"
  },
  {
    id: 5,
    category: "커뮤니티",
    title: "내가 작성한 글을 수정하려면 어떻게 해야 하나요?",
    content: `
    내가 작성한 글을 수정하려면 
    해당 글 페이지로 이동하여 '수정' 버튼을 클릭하고 
    내용을 수정한 후 수정완료 버튼을 클릭하면 저장됩니다.
    불필요한 논쟁을 피하기 위해 글 수정 시 신중하게 작성해 주시기 바랍니다.
    `,
    createDate: "2024-06-10",
    adminName: "관리자"
  },
  {
    id: 4,
    category: "회원정보",
    title: "두 개 이상의 계정을 가질 수 있나요?",
    content: `
    사이트 정책상 한 사람당 하나의 계정만 가질 수 있습니다.
    여러 개의 계정을 사용하는 것은 금지되어 있으며, 적발 시 모든 계정이 제한될 수 있습니다.
    계정 관리에 어려움이 있다면 고객센터로 문의해 주세요.
    `,
    createDate: "2024-06-11",
    adminName: "관리자"
  },
  {
    id: 3,
    category: "기타",
    title: "모바일 앱은 제공되나요?",
    content: `
    현재 모바일 앱은 제공되지 않지만, 모바일 웹사이트를 통해 모든 기능을 이용할 수 있습니다.
    모바일 웹사이트는 모바일 환경에 최적화되어 있어 편리하게 이용할 수 있습니다.
    모바일 앱 출시 일정에 대해서는 추후 공지될 예정입니다.
    `,
    createDate: "2024-06-12",
    adminName: "관리자"
  },
  {
    id: 2,
    category: "모임",
    title: "모임에 필요한 물품은 어떻게 준비하나요?",
    content: `
    모임에 필요한 물품은 모임 리더가 준비하며, 필요 시 참가자들에게 사전에 공지됩니다.
    참가자들은 공지된 물품을 각자 준비해 오시면 됩니다.
    필요한 물품 목록은 모임 페이지에서 확인할 수 있으며, 추가 문의는 모임 리더에게 해 주세요.
    `,
    createDate: "2024-06-13",
    adminName: "관리자"
  },
  {
    id: 1,
    category: "회원정보",
    title: "로그인 오류가 발생하면 어떻게 해야 하나요?",
    content: `
    로그인 오류가 발생하면 비밀번호 재설정을 시도하거나, 여전히 문제가 해결되지 않으면 고객센터로 문의해 주시기 바랍니다.
    고객센터는 24시간 운영되며, 빠르게 문제를 해결해 드리기 위해 최선을 다하고 있습니다.
    로그인 관련 문제 외에도 계정과 관련된 다른 문제들도 고객센터에서 보다 자세한 도움을 받을 수 있습니다.
    `,
    createDate: "2024-06-15",
    adminName: "관리자"
  }
];

export default faqMockData;
