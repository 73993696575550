import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axiosInstance from "../../axiosInstance";
import face from '../../HomeComponent/ReviewComponent/face.svg';
import './MoimDetail-BoardSchedule-Detail-Member.css';
import leaderIcon from '../../Img/moimDetail_leaderIcon.svg';
import managerIcon from '../../Img/moimDetail_managerIcon.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

const MoimDetailBoardScheduleDetailMember = ()=>{
  const {id,no} = useParams(); 
  const [moimScheduleInfo, setMoimScheduleInfo] = useState(); // 모임 일정
  const [moimMemberList,setMoimMemberList] = useState();
  const navigate = useNavigate();


// 🔒보안관련 (로그인 안했거나, 모임멤버 아닌경우 페이지 침입방지)
useEffect(() => {
  axiosInstance.get(`/getMoimMemberList/${id}`)
      .then((response) => {
        let page = window.location.href;
        let userInfo = JSON.parse(sessionStorage.getItem('userInfo'));
        let moimMemberList = response.data;
        let matchingMember = moimMemberList?.find(memberInfo => memberInfo.member.id === userInfo?.id); // 모임 멤버 확인
        // setMoimMemberInfo(matchingMember); //모임 멤버 객체 저장 (모임 멤버라면 값 들어가고 아니면 iundifind)
        // //console.log(matchingMember);
    
        // 😡😡😡나중에 주소 바꿔줘야함
        if (page !== `http://localhost:3000/moim/${id}/home`) { // 모임 메인 화면이 아닌 페이지를 url로 들어올 경우 (모임 메인 화면은 비회원도 볼 수 있음)
          if(userInfo){ //로그인 상태
              if(!matchingMember){ //모임멤버 아닌 경우
                alert("모임 가입 후 이용해주세요");
                navigate(`/moim/${id}/home`);
              }
          }else{ // 로그인 안한 상태
            alert("로그인 후 이용해주세요");
            navigate('/login');
          }
        }
      }).catch((error) => {
          console.log(error);
      });
}, [id]);


// 스케쥴, 참여 멤버 가져오기
useEffect(() => {
  const fetchData = async () => {
    try {
      // 모임 스케쥴 정보 가져오기
      const scheduleResponse = await axiosInstance.get(`/getMoimScheduleDetail/${no}`);
      setMoimScheduleInfo(scheduleResponse.data);

      // 모임 스케쥴 참여 멤버 가져오기
      const memberResponse = await axiosInstance.get(`/getMoimSheduleMemberList/${no}`);
      let memberList = memberResponse.data;

      // 이미지 URL을 가져오는 비동기 함수
      const fetchMemberImage = async (memberList) => {
        if (memberList.member && memberList.member.memberImage) {
          try {
            const imageResponse = await axiosInstance.get(`/userProfilePhoto/${memberList.member.id}`, {
              responseType: 'blob',
            });
            const imageUrl = URL.createObjectURL(imageResponse.data);
            console.log(imageUrl);
            return { ...memberList, memberImage: imageUrl };
          } catch (error) {
            console.log(error);
            return memberList;
          }
        } else {
          return { ...memberList, memberImage: 'https://raw.githubusercontent.com/Jella-o312/modo-image/main/etc/userImgNone.svg' };
        }
      };

      // 모든 멤버의 이미지를 병렬로 가져옴
      const updatedMemberList = await Promise.all(memberList.map(fetchMemberImage));

      // memberRole을 기준으로 정렬 (leader -> manager -> member)
      const sortedMembers = updatedMemberList.sort((a, b) => {
        if (a.memberRole === 'leader') return -1;
        if (a.memberRole === 'manager' && b.memberRole !== 'leader') return -1;
        return 1;
      });

      // 정렬된 데이터를 상태에 저장
      setMoimMemberList(sortedMembers);

    } catch (error) {
      console.log(error);
    }
  };

  fetchData();
}, [no]);



const backBtnHandler = ()=>{
  navigate(-1);
}

  return(
    <div className="moimSchedule-joinMember-container">
      <div className="moimSchedule-joinMember-headerBox">
        <div className='backBtn-title' style={{display:"flex", gap: '1rem'}}>
        <FontAwesomeIcon icon={faArrowLeft} size='lg'style={{color: 'gray', cursor: 'pointer', paddingTop: '0.2rem'}} onClick={backBtnHandler}/>
        <h5 className="title">모임 일정 참여 멤버 ({moimScheduleInfo?.joinedMember?.length} / {moimScheduleInfo?.scheduleMaxMember}명)</h5>
        </div>
        <div className="moimName">{moimScheduleInfo?.scheduleName} </div>
      </div>

      <div className="moimSchedule-joinMember-bodyBox">
      {
            moimMemberList?.map((data,i)=>(
              <div className='moimDetail-moimContent-home-member-content-modal' key={i} style={{paddingLeft: '0.3rem'}}>
                  <div className='moimDetail-moimContent-home-member-content-img-modal' style={{backgroundImage: `url(${data.memberImage})`}}>
                    {data.memberRole === 'leader' && <img className='moimDetail-moimLeaderIcon' src={leaderIcon} alt=''/>}
                    {data.memberRole === 'manager' && <img className='moimDetail-moimManagerIcon' src={managerIcon} alt=''/>}
                  </div>
                  <div className='moimDetail-moimContent-home-member-content-text'>
                    {/* <div>{data.member.nickname}</div> //나중에 바꿔야함 */} 
                    <div>{data.member.nickname}</div>
                    <span>{data.member.profileText === null ? '-' : data.member.profileText}</span>
                  </div>
                </div>
            ))
          }
      </div>
      
    </div>
  )
}

export default MoimDetailBoardScheduleDetailMember;