import React from "react"
import './TermsContents.css';

const TermsContents = {
    accessTerms: (
        <div >
            <span style={{fontWeight: 'bolder',  marginLeft: '5px'}}>제 1 조 (목적)</span>
            <p style={{margin: '5px'}}>
                이 약관은 회원이 (주)모도 광고사업부문(이하 “회사”)이 제공하는 헤이폴 및 이와 관련 제반 서비스(이하 “서비스”)의 이용과 관련하여 회사와 회원과의 권리, 의무 및 책임 사항, 기타 필요한 사항을 규정함을 목적으로 합니다.
            </p><br />

            <span style={{fontWeight: 'bolder',  marginLeft: '5px'}}>제 2 조 (정의)</span>
            <p style={{margin: '5px'}}>
                1. 이 약관에서 사용하는 용어의 정의는 다음과 같습니다.
                ①. “서비스”라 함은 “회원”이 이용할 수 있는 온라인 리서치, 설문조사 서비스 등 제10조에서 정하는 내용의 헤이폴 및 관련 제반 서비스를 의미합니다.
                ②. “홈페이지”라 함은 “서비스”를 제공하는 웹페이지 및 제반 시스템을 말합니다.
                ③. “어플리케이션”이라 함은 모바일 단말기에서 “서비스”를 설치하여 이용할 수 있도록 구성된 프로그램을 말합니다.
                ④. “회원”이라 함은 이 약관에 따라 “회사”와 이용 계약을 체결하고 “회사”가 제공하는 “서비스”를 이용하는 고객을 말합니다.
                ⑤. “이메일 아이디”라 함은 “회원”의 식별과 “서비스” 이용을 위하여 “회원”이 정하고 “회사”가 승인하는 이메일 형식의 문자와 숫자의 조합을 의미합니다.
                ⑥. “비밀번호”라 함은 “회원”이 부여 받은 “이메일 아이디” 와 일치되는 “회원”임을 확인하고 비밀 보호를 위해 “회원” 자신이 정한 영문 소문자, 숫자 및 특수문자가 포함된 조합을 의미합니다.
                ⑦. “유료서비스”라 함은 “회사”가 “서비스”의 제공과 관련하여 유료로 제공하는 설문조사, 각종 콘텐츠(투표 등록 등) 및 제반 서비스를 의미합니다.
                ⑧. “제휴포인트”라 함은 “회원”이 “헤이폴포인트”를 지급하여 전환할 수 있는 제휴사 또는 “회사”가 발행, 관리하는 적립 포인트를 의미합니다.
                ⑨. “제휴콘텐츠”라 함은 “회사”가 제휴를 통하여 마련하고 “회원”이 “헤이폴포인트”를 지급하여 이용할 수 있는 제휴사의 다양한 서비스를 의미합니다.
                ⑩. "헤이폴포인트"(이하 "포인트")란“회원”이 “회사”가 제공하는 “서비스”를 이용하여 취득하는 것으로서 다음 각 목에 따라 관리됩니다.
                가. 적립 포인트: 회원이 대가를 지급하지 않고 “회사”가 제공하는 “서비스” 이용 과정에서 “회사”가 별도로 정한 기준, 방식에 따라 적립 받아 “제휴콘텐츠” 이용 또는 “제휴포인트” 전환에 사용할 수 있는 포인트로서 “회사”가 발행, 관리하는 증표 또는 그 증표에 관한 정보를 의미
                나. “포인트” 소멸 : 포인트는 약관에서 정한 유효기간 내에서 사용할 수 있으며, 유효기간경과 시 자동 소멸
                ⑪. “회원등급”이란 회사가 지정한 기준에 따라 적용되는 계정별 등급으로써 회사는 회원등급에 따라 혜택을 제공합니다.
                ⑫. “제휴사”란 “제휴콘텐츠”를 제공하기로 “회사”와 제휴한 업체를 의미합니다.
                2. 이 약관에서 사용하는 용어의 정의는 제1항에서 정하는 것을 제외하고는 관계법령, 상관례 및 서비스별 안내에서 정하는 바에 의합니다.
            </p><br />

            <span style={{fontWeight: 'bolder',  marginLeft: '5px'}}>제 3 조 (약관의 효력 및 변경)</span>
            <p style={{margin: '5px'}}>
                1. 이 약관은 “서비스”를 이용하고자 하는 모든 “회원”에 대하여 그 효력이 발생합니다.
                2. “회사”는 이 약관의 내용을 “회원”이 쉽게 알 수 있도록 “서비스” “홈페이지” 등의 화면에 게시하거나 기타의 방법으로 “회원”에게 공지합니다.
                3. “회사”는 “약관의 규제에 관한 법률”, “정보 통신망 이용 촉진 및 정보 보호 등에 관한 법률(이하 “정보통신망법”)” 등 관련 법령을 위배하지 않는 범위에서 이 약관을 개정할 수 있습니다.
                4. “회사”가 약관을 개정할 경우에는 제2항의 방식에 따라 개정된 약관의 적용일자 7일 전에 공지합니다. 다만, “회원”에게 불리한 약관의 변경인 경우에는 그 적용일자 30일 전 부터 공지하도록 합니다.
                5. “회사”가 제4항에 따라 개정 약관을 공지하면서 “회원”에게 약관의 효력 발생일 이전에 거부 의사표시를 하지 않으면 약관의 변경에 동의한 것으로 간주한다는 내용을 명확하게 공지 또는 통지하였음에도 “회원”이 명시적으로 개정된 약관의 효력 발생일까지 거부의 의사표시를 하지 아니한 경우 “회원”이 개정약관에 동의한 것으로 봅니다.
                6. “회원”이 개정 약관의 적용에 동의하지 않는 경우 “회사”는 개정 약관의 내용을 적용할 수 없으며, 이 경우 “회사” 또는 “회원”은 이용 계약을 해지할 수 있습니다.
            </p><br />

            <span style={{fontWeight: 'bolder',  marginLeft: '5px'}}>제4조 (이용계약의 성립)</span>
            <p style={{margin: '5px'}}>
                1. “회원”이 되고자 하는 자(이하 “가입신청자”)는 “서비스” 관련 홈페이지 (heypoll.co.kr) 및 어플리케이션에서 “약관” 과 “개인 정보의 수집, 제공 및 활용에 관한 동의서”의 내용에 대하여 동의하고 “회사”가 요구하는 기본 회원 정보를 입력하여 회원 가입 신청을 하고 “회사”가 이러한 신청에 대하여 승낙함으로써 이용 계약이 체결됩니다.
                2. “회사”는 “가입신청자”의 신청에 대하여 “서비스” 이용을 승낙함을 원칙으로 합니다. 다만, “회사”는 다음 각 호에 해당하는 신청에 대하여 승낙을 하지 않거나 사후에 이용 계약을 해지할 수 있습니다.
                ① “가입신청자”가 이 약관에 의하여 이전에 회원 자격을 상실한 적이 있는 경우 (단, “회사”의 회원 재가입 승낙을 얻은 경우에는 예외로 함)
                ② 실명이 아니거나 타인의 명의를 이용한 경우
                ③ 허위의 정보를 기재하거나, “회사”가 제시하는 내용을 기재하지 않은 경우
                ④ “가입신청자”가 14세 미만 아동인 경우
                ⑤ 이용자의 귀책사유로 인하여 승인이 불가능하거나 기타 회사가 규정한 “서비스” 이용신청 관련 제반 사항을 위반하며 신청하는 경우
                3. “회사”는 “서비스” 관련 설비의 여유가 없거나, 기술상 또는 업무상 문제가 있는 경우에는 승낙을 유보할 수 있습니다.
                4. 제2항과 제3항에 따라 회원 가입 신청의 승낙을 하지 않거나 유보한 경우, “회사”는 원칙적으로 이를 “가입신청자”에게 알리도록 합니다.
                5. “회사”는 “회원”에 대해 회사 정책에 따라 등급 별로 구분하여 이용 시간, 이용 횟수, 서비스 메뉴 등을 세분하여 이용에 차등을 둘 수 있습니다.
            </p><br />

            <span style={{fontWeight: 'bolder',  marginLeft: '5px'}}> 5 조 (회원 정보의 변경 및 탈퇴)</span>
            <p style={{margin: '5px'}}>
                1. “회원”은 회원 정보 관리 화면을 통하여 본인의 정보를 열람하고 수정할 수 있습니다.
                2. “회원”은 회원 가입 신청 시 기재한 사항이 변경되었을 경우 홈페이지나 어플리케이션을 통해 수정을 하거나 전자우편 기타 방법으로 “회사”에 대하여 그 변경 사항을 알려야 합니다.
                3. 제2항의 변경 사항을 “회사”에 알리지 않아 발생한 불이익에 대하여 “회사”는 책임지지 않습니다.
                4. ”회원”은 언제든지” 홈페이지”에서 회사가 정하는 방법으로 회원 탈퇴를 요청할 수 있으며, “회사”는 “회원”의 요청에 따라 조속히 탈퇴에 필요한 제반 절차를 수행합니다.
                5. 제4항의 탈퇴시 잔여 “포인트”는 즉시 소멸하며 다른 개인정보는 회사의 “개인정보처리방침”에 따라 파기됩니다.
                6. 해지로 인하여 “제휴포인트”와 “제휴콘텐츠” 이용에 대한 관련 정보가 삭제됨에 따라 회원에게 발생하는 손해에 대해 “회사”는 책임지지 않습니다.
                7. 제4항에 따라 탈퇴한 “회원”에 대해서 “회사”는 서비스 운영 원칙에 따라 90일 동안 재가입을 제한할 수 있습니다.
            </p><br />

            <span style={{fontWeight: 'bolder',  marginLeft: '5px'}}>제 6 조 (“회원”의 “이메일아이디” 및 “비밀번호”의 관리에 대한 의무)</span>
            <p style={{margin: '5px'}}>
                1. “회원”의 “이메일아이디”와 “비밀번호”에 관한 관리 책임은 “회원”에게 있으며, 이를 제3자가 이용하도록 하여서는 안 됩니다.
                2. “회사”는 “회원”의 “이메일아이디”가 “회사” 및 “회사”의 운영자로 오인할 우려가 있는 경우,기 가입한 “회원”의 “이메일아이디” 로서 중복의 우려가 있는 등의 경우, 해당 “이메일아이디”의 이용을 제한할 수 있습니다.
                3. “회원”은 “이메일아이디” 및 “비밀번호”가 도용 되거나 제3자가 사용하고 있음을 인지한 경우에는 이를 즉시 “회사”에 통지하고 “회사”의 안내에 따라야 합니다.
                4. 제3항의 경우에 해당 “회원”이 “회사”에 그 사실을 통지하지 않거나, 통지한 경우에도 “회사”의 안내에 따르지 않아 발생한 불이익에 대하여 “회사”는 책임지지 않습니다.
            </p><br />

            <span style={{fontWeight: 'bolder',  marginLeft: '5px'}}>제 7 조 (“회원”에 대한 통지)</span>
            <p style={{margin: '5px'}}>
                1. “회사”가 “회원”에 대한 통지를 하는 경우 이 약관에 별도 규정이 없는 한 “서비스” 내 “회원”이 등록한 전자 우편 주소, SMS 등으로 할 수 있습니다.
                2. “회사”는 “회원” 전체 또는 불특정 다수 “회원”에 대한 통지의 경우“회사”의 “서비스” “홈페이지”에 게시함으로써 제1항의 개별 통지에 갈음할 수 있습니다.
                3. “회사”는 다음의 경우 사전 통보 없이 해당 회원과의 이용 계약을 해지하여 회원 자격을 상실시킬 수 있습니다.
                ① 회원이 사망한 경우
                ② 타인의 개인정보 또는 모바일 기기를 도용한 경우
                ③ 회원이 가입 시 작성한 개인정보가 허위임을 밝혀진 경우
                ④ 포인트를 부정한 방법으로 적립하거나 사용한 경우
                ⑤ 3차 불성실 응답에 대한 안내 메일을 수신하였을 때
                4. 제 3항의 사유로 자격상실 통보를 받은 회원의 포인트는 통보 즉시 소멸되고, 회원은 이의를 제기할 수 없습니다.
                5. 회사는 위의 사유가 있는 경우 자격상실 대신 이용의 제한을 가할 수 있습니다.
            </p><br />

            <span style={{fontWeight: 'bolder',  marginLeft: '5px'}}>제 8 조 (“회사”의 의무)</span>
            <p style={{margin: '5px'}}>
                1. “회사”는 관련 법과 이 약관이 금지하거나 미풍양속에 반하는 행위를 하지 않으며, 계속적이고 안정적으로 “서비스”를 제공하기 위하여 최선을 다하여 노력합니다.
                2. “회사”는 “회원”이 안전하게 “서비스”를 이용할 수 있도록 개인정보(신용정보 포함)보호를 위해 보안 시스템을 갖추어야 하며 개인정보 처리방침을 공시하고 준수합니다.
                3. “회사”는 “서비스” 이용과 관련하여 “회원”이 제기한 의견이나 불만이 정당하다고 인정할 경우에는 이를 처리하여야 합니다. “회원”이 제기한 의견이나 불만 사항에 대해서는 게시판을 활용하거나 전자우편 등을 통하여 “회원”에게 처리 과정 및 결과를 전달합니다.
                4. “회사”가 제공하는 “서비스”로 인하여 “회원”에게 손해가 발생한 경우 그러한 손해가 회사의 고의나 과실에 기해 발생한 경우에 한하여 “회사”에서 책임을 부담하며, 그 책임의 범위는 통상손해에 한하며, 특별한 사정으로 인한 손해는 “회사”가 그 사정을 알았거나 알 수 있었을 때에 배상책임이 있습니다.
                5. “회사”는 정보통신망 이용촉진 및 정보보호 등에 관한 법률, 전기통신사업법 등 “서비스”의 운영, 유지와 관련한 법규를 준수합니다.
            </p><br />

            <span style={{fontWeight: 'bolder',  marginLeft: '5px'}}>제 9 조 (“회원”의 의무)</span>
            <p style={{margin: '5px'}}>
                1. “회원”은 다음 행위를 하여서는 안 됩니다.
                ① “회원”신청 또는 “회원” 정보 변경 시 허위 내용의 등록
                ② “회원”신청 또는 “회원” 정보 변경 시 타인의 정보 도용
                ③ “회사”가 게시한 정보의 변경
                ④ 다른 회원의 개인정보 및 계정정보를 관련 법령 및 “회사”가 허용하지 않는 방법으로 수집하는 행위
                ⑤ “회사”의 동의없이 영리를 목적으로 “서비스”를 사용하는 행위
                ⑥ 리버스엔지니어링, 디컴파일, 디스어셈블 및 기타 일체의 가공행위를 통하여 “서비스”를 복제, 분해 또는 모방 기타 변형하는 행위
                ⑦ 자동 접속 프로그램 등을 사용하는 등 정상적인 용법과 다른 방법으로 “서비스”를 이용하여 “회사”의 서버에 부하를 일으켜 회사의 정상적인 “서비스”를 방해하는 행위
                ⑧ 본인 아닌 제3자에게 접속 권한을 부여하는 행위
                ⑨ “회사” 가 제공하는 “서비스” 이용방법에 의하지 아니하고 비정상적인 방법으로 “서비스”를 이용하거나 “서비스” 관련 시스템에 접속하여 사용하는 행위
                ⑩ “회사”와 기타 제3자의 저작권 등 지적재산권에 대한 침해
                ⑪ “회사” 및 기타 제3자의 명예를 손상 시키거나 업무를 방해하는 행위
                ⑫ 외설 또는 폭력적인 메시지, 화상, 음성, 기타 공서양속에 반하는 정보를 “서비스”에 공개 또는 게시하는 행위
                ⑬ 기타 관련 법령상 불법이거나 서비스 운영 원칙에 위배되는 행위
                2. “회원”은 관계법, 이 약관의 규정, 서비스 운영 원칙, 이용 안내 및 “서비스”와 관련하여 공지한 주의 사항, “회사”가 통지하는 사항 등을 준수하여야 하며, 기타 “회사”의 업무에 방해되는 행위를 하여서는 안 됩니다. 만약 이를 위반할 경우 “회사”는 별도 공지 없이 회원 자격을 박탈할 수 있습니다.
                3. “회원”은 “서비스” 이용 시 “서비스”에서 요구하는 질의응답에 대해 거짓없이, 성실하게 응해야 합니다.
                4. 본 조에서 정한 의무를 위반할 경우, “회사”는 “회원”에게 지급한 “헤이폴포인트”를 환수할 수 있으며, “헤이폴포인트”를 지급하여 사용하거나 전환한 “제휴콘텐츠” 또는 “제휴포인트”에 상응하는 비용을 청구할 수 있습니다. 또한, 향후 서비스 참여를 제한하거나, 회원 자격을 박탈할 수 있습니다.
            </p><br />

            <span style={{fontWeight: 'bolder',  marginLeft: '5px'}}>제 10 조 (서비스의 제공)</span>
            <p style={{margin: '5px'}}>
                1. “회사”는 “회원”에게 다음과 같은 “서비스”를 제공합니다.
                ① 설문조사 참여 서비스
                ② 설문조사 관련 뉴스 서비스
                ③ 기타 “회사”가 추가 개발하거나 다른 회사와의 제휴 계약 등을 통해 “회원”에게 제공하는 일체의 서비스
                2. “회사”는 “서비스”를 일정 범위로 분할하여 각 범위 별로 이용 가능 시간을 별도로 지정할 수 있습니다. 다만, 이러한 경우에는 그 내용을 사전에 공지합니다.
            </p><br />

            <span style={{fontWeight: 'bolder',  marginLeft: '5px'}}>제11조(서비스의 변경 및 중단)</span>
            <p style={{margin: '5px'}}>
                1. “회사”는 컴퓨터 등 정보통신설비의 보수점검, 교체 및 고장, 통신두절 또는 운영상 상당한 이유가 있는 경우 “서비스”의 제공을 일시적으로 중단하거나 변경할 수 있습니다. 이 경우 “회사”는 제7조에 정한 방법으로 “회원”에게 통지합니다. 다만, “회사”가 사전에 통지할 수 없는 부득이한 사유가 있는 경우 사후에 통지할 수 있습니다.
                2. “회사”는 “서비스”의 제공에 필요한 경우 정기 점검을 실시할 수 있으며, 정기 점검 시간은 “서비스” 제공 화면에 공지한 바에 따릅니다.
                3. “회사”는 무료로 제공되는 “서비스”의 일부 또는 전부를 “회사”의 정책 및 운영의 필요상 수정, 중단, 변경할 수 있으며, 이에 대하여 관련법에 특별한 규정이 없는 한 “회원”에게 별도의 보상을 하지 않습니다.
            </p><br />

            <span style={{fontWeight: 'bolder',  marginLeft: '5px'}}>제 12 조 (정보의 제공 및 광고의 게재)</span>
            <p style={{margin: '5px'}}>
                1. “회사”는 “회원”이 “서비스” 이용 중 필요하다고 인정되는 “회사”의 서비스 등 다양한 정보를 공지사항, 서비스 알림, 전자우편 등의 방법으로 “회원”에게 제공할 수 있습니다. 다만, “회원”은 관련법에 따른 거래관련 정보 및 고객문의 등에 대한 답변 등을 제외하고는 위 정보 중 영리목적의 광고성 정보의 경우 언제든지 전자우편 등에 대해서 수신 거절을 할 수 있습니다.
                2. 제1항의 정보 중 영리목적의 광고성 정보를 전화 및 모사전송기기에 의하여 전송하려고 하는 경우에는 “회원”의 사전 동의를 받아서 전송합니다.
            </p><br />

            <span style={{fontWeight: 'bolder',  marginLeft: '5px'}}>제 13 조 (“유료서비스”의 구매)</span>
            <p style={{margin: '5px'}}>
                1. “회원”은 “회사”가 “서비스”를 통하여 판매, 제공하는 “유료 서비스”를 구매하기 전에 반드시 “회사”가 “홈페이지” 내에 작성한 “유료 서비스”의 상세 내용과 거래의 조건을 정확하게 확인한 후 구매를 하여야 합니다. 구매하려는 “유료 서비스”의 내용과 거래의 조건을 확인하지 않고 구매하여 발생한 모든 손해에 대한 책임은 “회원” 본인에게 있습니다.
                2. “회사”는 “유료 서비스”의 품절 또는 “유료 서비스” 제공 관련 기술적 사양의 변경 등으로 인하여 제공할 “유료 서비스”의 내용이 기존에 개제한 “유료 서비스”의 내용과 다를 경우에는 장차 제공할 “유료 서비스”의 내용을 변경할 수 있습니다. 이 경우 변경된 “유료 서비스”의 내용을 명시하여 현재의 재화 또는 용역의 내용을 게시한 곳에 공지합니다.
                3. “회사”는 “회원”이 신청한 “유료서비스” 등을 제공 할 수 없을 경우 지체 없이 그 사유를 해당 “회원”에게 통지하고, 그 통지일로부터 “회사”가 제공할 수 있는 가장 빠른 시일 이내에 계약 해지 및 환급 절차를 취합니다.
            </p><br />

            <span style={{fontWeight: 'bolder',  marginLeft: '5px'}}>제14조(청약 철회 등에 관한 규정)</span>
            <p style={{margin: '5px'}}>
                1. “서비스”를 통하여 “회원”이 구매하는 “유료 서비스”는 구매 즉시 이용 가능한 상태가 되거나 이용하는 것으로 볼 수 있는 경우(“회원”이 의뢰한 설문조사의 전부 또는 일부의 착수가 이미 완료된 경우 등) 그 상품의 성격상 그리고 관계법령(전자상거래 등에서의 소비자보호에 관한 법률, 콘텐츠산업진흥법 상의 청약철회 불가사유 해당 등)상 구매 후에는 청약철회가 불가할 수 있습니다. 다만, 이 경우 “회사”는 청약철회가 불가능한 사실을 표시사항에 포함한 경우이거나 시험상품을 제공하는 등의 방법의 조치를 취합니다.
                2. “회원”의 사용 또는 소비로 재화의 가치가 현저히 감소한 경우로 볼 수 없는 경우 등 본 조 제1항의 청약철회가 제한되는 경우 외에는 “회원”은 이미 구매 완료된 “유료 서비스”이라도 “회사”에 청약철회, 환불 요청 등을 할 수 있습니다. “회사”는 해당 요청에 대해 심사하여 그 결과를 “회원”에게 통보합니다.
                3. “회사”는 필요한 경우 청약철회 등을 요청하는 “회원”에게 청약철회 등에 대한 자료의 제공을 요청할 수 있습니다.
                4. 청약철회, 환급요청 기간은 관련 법령(전자상거래 등에서의 소비자보호에 관한 법률 등)에서 규정한 기간에 따르며, 구체적인 기간은 다음과 같습니다.
                ① “유료 서비스”를 공급받은 날 또는 상품 공급에 대한 계약서, 통지 등을 받은 날로부터 7일 이내
                ② 공급된 “유료 서비스”의 내용이 표시, 광고된 내용과 다르거나 계약 내용과 다르게 이행된 경우에는 해당 상품을 공급받은 날로부터 3개월 이내, 또는 그 사실을 알거나 알 수 있었던 날로부터 30일 이내
                5. 본 조 제2항에 따라 청약을 철회한 “회원”은 ”회사”로부터 제공받은 “유료 서비스”의 반환이 가능한 경우 이를 반환하여야 하며, 디지털 상품, 다운로드 받은 콘텐츠 등과 같이 반환하더라도 동일한 상품이 “회원”에게 남아있는 경우에는 이를 삭제하여야 합니다.
                6. “회사”는 본 조 제4항에 따라 “유료 서비스” 관련 법령(전자상거래 등에서의 소비자보호에 관한 법률 등)에서 규정하는 기간 내에 지급받은 대금이 있는 경우 환급하여야 하며, 이 경우 “회원”이 해당 “유료 서비스”를 이용하여 얻은 이익이 있는 경우 해당 금액을 공제하고 환급할 수 있습니다.
                7. “회사”가 “회원”에게 대금을 환급할 때에 이용대금의 결제와 동일한 방법으로 결제대금의 전부 또는 일부를 환급하며, 동일한 방법으로 환급이 불가능할 경우에 대안을 제시하며 “회원”의 선택에 따라 환급합니다.
            </p><br />

            <span style={{fontWeight: 'bolder',  marginLeft: '5px'}}>제 15 조 (게시물 또는 내용물의 삭제)</span>
            <p style={{margin: '5px'}}>
                1. “회사”는 “회원”이 게시하거나 전달하는 모든 게시물이 다음 각 호의 경우에 해당한다고 판단되는 경우 사전통지 없이 게시, 전달 등을 중단할 수 있으며, 이에 대해 “회사”는 어떠한 책임도 지지 않습니다.
                ① “회사”, 다른 회원 또는 제3자를 비방하거나 중상모략으로 명예를 손상시키는 내용인 경우
                ② 공공질서 및 미풍양속에 위반되는 내용의 게시물 유포 등에 해당하는 경우
                ③ 범죄 행위에 결부된다고 인정되는 내용인 경우
                ④ “회사” 또는 제3자의 저작권 등 기타 권리를 침해하는 내용인 경우
                ⑤ 본 조 제2항 소정의 세부이용지침을 통하여 “회사”에서 규정한 게시기간을 초과한 경우
                ⑥ 불필요하거나 승인되지 않은 광고, 판촉물을 게재하는 경우
                ⑦ 기타 관계 법령 및 “회사”의 지침 등에 위반된다고 판단되는 경우
                2. “회사”는 게시물에 관련된 세부이용지침을 별도로 정하여 시행할 수 있으며, “회원”은 그 지침에 따라 각종 게시물을 등록하거나 삭제하여야 합니다.
            </p><br />

            <span style={{fontWeight: 'bolder',  marginLeft: '5px'}}>제 16 조 (헤이폴포인트)</span>
            <p style={{margin: '5px'}}>
                1. “회사”는 “회원”이 “서비스” 이용 및 참여에 대한 보상으로 “헤이폴포인트”를 무상으로 지급, 발행할 수 있습니다. “헤이폴포인트”의 지급 조건은 “회사”가 별도로 정하는 운영 원칙에 따릅니다.
                2. 1”포인트”는 1원의가치가 있으며, 본인인증이 되어 있지 않은 “회원”의 경우 “포인트”의 사용이 제한될 수 있습니다.
                3. “회원”은 “포인트”를 다른 “회원”에게 선물할 수 있습니다. 단, “포인트”를 선물한 이후에는 철회가 불가하며 이에 대한 모든 책임은 “회원”에게 있습니다.
                4. “포인트”는 양도, 상속, 대여, 담보로 제공할 수 없습니다. 다만 회사가 인정하는 경우 예외로 합니다.
                5. “제휴콘텐츠” 이용, 적립 등에 관한 사항은 각 제휴사 이용 약관을 따릅니다. 단, 회사가 시행하는 이벤트, 프로모션 등을 통해 무상으로 지급받은 “제휴포인트” 및 “제휴콘텐츠”에 대해서는 환불이 불가하며, 유효기간 만료 시 “제휴콘텐츠”는 자동 소멸되어 복구되지 않습니다.
                6. “회사”는 “회원”이 “서비스” 이용 및 참여 시 허위 정보를 입력하거나, “회사”가 정한 “포인트” 지급 조건에 위반한 경우, 불법적이거나 부당한 행위를 통하여 “회원”이 실제로 “서비스” 이용 및 참여를 하지 않았음에도 불구하고 당해 “회원”에게 “포인트”가 적립된 경우, “회사”의 시스템 오류 등 기술적 문제 등 운영상의 오류로 “회원”에게 “헤이폴포인트”가 오지급 된 경우 등에는 지급한 “포인트”를 회수할 수 있습니다.
                7. “회원”은 “헤이폴포인트”를 회사가 정한 절차에 따라 모바일 상품권 등 “제휴콘텐츠"를 구입하거나 “제휴포인트”로 적립하는데 사용할 수 있습니다. 이 경우 “회사”는 전문 인증 기관을 통한 실명 확인 및 본인 인증을 요청할 수 있습니다.
                8. “적립 포인트”는 회원의 계정이 “회사”의 서비스 운영 원칙에 따라 휴면계정으로 전환된 경우, “적립 포인트”의 유효기간과 관계없이 휴면계정으로 전환된 날 로부터 90일이경과한 일자에 일괄적으로 자동 소멸됩니다.
                9. 적립된 “포인트”의 유효기간은 적립월로부터 5년(60개월) 입니다. 회사및 제휴사가 이벤트 등의 마케팅 활동으로 회원에게 추가로 제공한 포인트의 유효기간은 회사 및 제휴사의 정책에 따라 별도로 책정할 수 있으며 이는별도로 공지합니다.
                10. “포인트”를 적립한 후 사용하지않고 유효기간이 경과된 “포인트”는 월 단위로 선입선출방식에 의하여 자동 소멸되며, 그 유효기간 경과분에한해 “포인트”의 적립시기를 모두 적립월의 말일자로 적립된 것으로 간주하여 그 경과가 도래한 날의 익월 1일자에소멸됩니다. 단, 회사 및 제휴사가 이벤트 등의 마케팅 활동으로회원에게 추가로 제공한 포인트의 유효기간은 회사 및 제휴사의 정책에 따라 별도로 책정할 수 있습니다.
                예시)
                - 2022년 7월 1일 적립된 포인트는 2027년 8월 1일자로소멸
                - 2022년 7월 15일 적립된 포인트는 2027년 8월 1일자로소멸
                11. “회사”는 “포인트”의 유효기간이 도래하여 “포인트”를 소멸시키는 경우 소멸예정 “포인트”, 소멸 시기 등 “포인트” 소멸과 관련된 내용에 대해 별도의 고지 의무를 부담하지 않습니다. 다만, 회사 및 제휴사 홈페이지에서 소멸예정 “포인트” 확인이 가능하도록하는 등 “포인트” 소멸과 관련된 내용을 고객이 인지할 수 있도록 조치를 취합니다.
                12. “회원”이 탈퇴∙자격상실∙”포인트”의 유효기간 도래 등을 원인으로 “포인트”가 소멸되는 경우“포인트”는 현금으로 반환되지 않고 소멸되며, 소멸된 “포인트”는 복구가 불가능합니다.
            </p><br />

            <span style={{fontWeight: 'bolder',  marginLeft: '5px'}}>제 17 조 (책임 제한)</span>
            <p style={{margin: '5px'}}>
                1. “회사”는 천재지변 또는 이에 준하는 불가항력으로 인하여 “서비스”를 제공할 수 없는 경우에는 “서비스” 제공에 관한 책임을 지지 않습니다.
                2. “회사”는 “회원”의 귀책사유로 인한 “서비스” 이용의 장애에 대하여 책임을 지지 않습니다.
                3. “회사”는 “회원”이 “서비스”와 관련하여 게재한 정보, 자료, 사실의 신뢰도, 정확성 등의 내용에 관하여 책임을 지지 않습니다.
                4. “회사”는 “회원” 간 또는 “회원”과 제3자 상호 간에 “서비스”를 매개로 하여 거래 등을 한 경우에 대하여 책임을 지지 않습니다.
                5. “회사”는 무료로 제공되는 서비스 이용과 관련하여 관련법에 특별한 규정이 없는 한 책임을 지지 않습니다.
            </p><br />

            <span style={{fontWeight: 'bolder',  marginLeft: '5px'}}>제 18 조 (준거법 및 관할법원)</span>
            <p style={{margin: '5px'}}>
                1. 본 약관에서 정하지 않은 사항과 본 약관의 해석에 관하여는 대한민국법 및 상관례에 따릅니다.
                2. 서비스 및 본 약관과 관련한 제반 분쟁 및 소송은 서울중앙지방법원 또는 민사 소송법상의 관할 법원을 제1심 관할 법원으로 합니다.
            </p><br />

            <span style={{fontWeight: 'bolder',  marginLeft: '5px'}}>부칙</span>
            <p>
                (시행일) 본 약관은 2022년 7월 1일부터 적용됩니다.
            </p><br />

        </div>
    ),

    infoTerms: (
        <div>
            <p style={{margin: '5px'}}>
                개인정보보호법에 따라 모도에 회원가입 신청하시는 분께 수집하는 개인정보의 항목,
                개인정보의 수집 및 이용목적, 개인정보의 보유 및 이용기간, 동의 거부권 및 동의 거부 시
                불이익에 관한 사항을 안내 드리오니 자세히 읽은 후 동의하여 주시기 바랍니다.
            </p>

            <br />

            <span style={{fontWeight: 'bolder',  marginLeft: '5px'}}> 1. 수집하는 개인정보</span><br />
            <p style={{margin: '5px'}}>
                이용자는 회원가입을 하지 않아도 정보 검색, 뉴스 보기 등 대부분의 모도 서비스를 회원과 동일하게 이용할 수 있습니다. 이용자가 메일, 캘린더, 카페, 블로그 등과 같이 개인화 혹은 회원제 서비스를 이용하기 위해 회원가입을 할 경우, 모도는 서비스 이용을 위해 필요한 최소한의 개인정보를 수집합니다.

                회원가입 시점에 모도가 이용자로부터 수집하는 개인정보는 아래와 같습니다.
                - 회원 가입 시 필수항목으로 아이디, 비밀번호, 이름, 생년월일, 성별, 휴대전화번호를, 선택항목으로 본인확인 이메일주소를 수집합니다. 실명 인증된 아이디로 가입 시, 암호화된 동일인 식별정보(CI), 중복가입 확인정보(DI), 내외국인 정보를 함께 수집합니다. 만14세 미만 아동의 경우, 법정대리인 정보(법정대리인의 이름, 생년월일, 성별, 중복가입확인정보(DI), 휴대전화번호)를 추가로 수집합니다.
                - 비밀번호 없이 회원 가입 시에는 필수항목으로 아이디, 이름, 생년월일, 휴대전화번호를, 선택항목으로 비밀번호를 수집합니다.
                - 단체 회원가입 시 필수 항목으로 단체아이디, 비밀번호, 단체이름, 이메일주소, 휴대전화번호를, 선택항목으로 단체 대표자명을 수집합니다.
                서비스 이용 과정에서 이용자로부터 수집하는 개인정보는 아래와 같습니다.
                - 회원정보 또는 개별 서비스에서 프로필 정보(별명, 프로필 사진)를 설정할 수 있습니다. 회원정보에 별명을 입력하지 않은 경우에는 마스킹 처리된 아이디가 별명으로 자동 입력됩니다.
                - 모도 내의 개별 서비스 이용, 이벤트 응모 및 경품 신청 과정에서 해당 서비스의 이용자에 한해 추가 개인정보 수집이 발생할 수 있습니다. 추가로 개인정보를 수집할 경우에는 해당 개인정보 수집 시점에서 이용자에게 ‘수집하는 개인정보 항목, 개인정보의 수집 및 이용목적, 개인정보의 보관기간’에 대해 안내 드리고 동의를 받습니다.

                서비스 이용 과정에서 IP 주소, 쿠키, 서비스 이용 기록, 기기정보, 위치정보가 생성되어 수집될 수 있습니다. 또한 이미지 및 음성을 이용한 검색 서비스 등에서 이미지나 음성이 수집될 수 있습니다.
                구체적으로 1) 서비스 이용 과정에서 이용자에 관한 정보를 자동화된 방법으로 생성하여 이를 저장(수집)하거나, 2) 이용자 기기의 고유한 정보를 원래의 값을 확인하지 못 하도록 안전하게 변환하여 수집합니다.
                서비스 이용 과정에서 위치정보가 수집될 수 있으며,
                모도에서 제공하는 위치기반 서비스에 대해서는 '모도 위치기반서비스 이용약관'에서 자세하게 규정하고 있습니다.
                이와 같이 수집된 정보는 개인정보와의 연계 여부 등에 따라 개인정보에 해당할 수 있고, 개인정보에 해당하지 않을 수도 있습니다.
            </p><br />


            <span style={{fontWeight: 'bolder',  marginLeft: '5px'}}>2. 수집한 개인정보의 이용</span><br />
            <p style={{margin: '5px'}}>
                모도 및 모도 관련 제반 서비스(모바일 웹/앱 포함)의 회원관리, 서비스 개발・제공 및 향상, 안전한 인터넷 이용환경 구축 등 아래의 목적으로만 개인정보를 이용합니다.

                - 회원 가입 의사의 확인, 연령 확인 및 법정대리인 동의 진행, 이용자 및 법정대리인의 본인 확인, 이용자 식별, 회원탈퇴 의사의 확인 등 회원관리를 위하여 개인정보를 이용합니다.
                - 콘텐츠 등 기존 서비스 제공(광고 포함)에 더하여, 인구통계학적 분석, 서비스 방문 및 이용기록의 분석, 개인정보 및 관심에 기반한 이용자간 관계의 형성, 지인 및 관심사 등에 기반한 맞춤형 서비스 제공 등 신규 서비스 요소의 발굴 및 기존 서비스 개선 등을 위하여 개인정보를 이용합니다. 신규 서비스 요소의 발굴 및 기존 서비스 개선 등에는 정보 검색, 다른 이용자와의 커뮤니케이션, 콘텐츠 생성·제공·추천, 상품 쇼핑 등에서의 인공지능(AI) 기술 적용이 포함됩니다.
                - 법령 및 모도 이용약관을 위반하는 회원에 대한 이용 제한 조치, 부정 이용 행위를 포함하여 서비스의 원활한 운영에 지장을 주는 행위에 대한 방지 및 제재, 계정도용 및 부정거래 방지, 약관 개정 등의 고지사항 전달, 분쟁조정을 위한 기록 보존, 민원처리 등 이용자 보호 및 서비스 운영을 위하여 개인정보를 이용합니다.
                - 유료 서비스 제공에 따르는 본인인증, 구매 및 요금 결제, 상품 및 서비스의 배송을 위하여 개인정보를 이용합니다.
                - 이벤트 정보 및 참여기회 제공, 광고성 정보 제공 등 마케팅 및 프로모션 목적으로 개인정보를 이용합니다.
                - 서비스 이용기록과 접속 빈도 분석, 서비스 이용에 대한 통계, 서비스 분석 및 통계에 따른 맞춤 서비스 제공 및 광고 게재 등에 개인정보를 이용합니다.
                - 보안, 프라이버시, 안전 측면에서 이용자가 안심하고 이용할 수 있는 서비스 이용환경 구축을 위해 개인정보를 이용합니다.
            </p><br />

            <span style={{fontWeight: 'bolder',  marginLeft: '5px'}}>3. 개인정보의 보관기간</span><br />
            <p style={{margin: '5px'}}>
                회사는 원칙적으로 이용자의 개인정보를 회원 탈퇴 시 지체없이 파기하고 있습니다.
                단, 이용자에게 개인정보 보관기간에 대해 별도의 동의를 얻은 경우, 또는 법령에서 일정 기간 정보보관 의무를 부과하는 경우에는 해당 기간 동안 개인정보를 안전하게 보관합니다.
                이용자에게 개인정보 보관기간에 대해 회원가입 시 또는 서비스 가입 시 동의를 얻은 경우는 아래와 같습니다.
                - 부정 가입 및 이용 방지
                부정 이용자의 가입인증 휴대전화번호 또는 DI (만14세 미만의 경우 법정대리인DI) : 탈퇴일로부터 6개월 보관
                탈퇴한 이용자의 휴대전화번호(복호화가 불가능한 일방향 암호화(해시처리)) : 탈퇴일로부터 6개월 보관
                - QR코드 복구 요청 대응
                QR코드 등록 정보:삭제 시점으로부터6개월 보관
                - 스마트플레이스 분쟁 조정 및 고객문의 대응
                휴대전화번호:등록/수정/삭제 요청 시로부터 최대1년
                - 모도 플러스 멤버십 서비스 혜택 중복 제공 방지
                암호화처리(해시처리)한DI :혜택 제공 종료일로부터6개월 보관
                - 모도 엑스퍼트 재가입 신청 및 부정 이용 방지
                모도 엑스퍼트 서비스 및 모도 엑스퍼트 센터 가입 등록정보&판매자 이름 : 신청일로부터 6개월(등록 거절 시, 거절 의사 표시한 날로부터 30일) 보관
                - 전자서명 인증 업무
                개인정보: 모도 인증서 서비스 해지 시까지 보관
                인증서와 인증 업무에 관한 기록: 인증서 효력 상실일로부터 5년 보관
                전자상거래 등에서의 소비자 보호에 관한 법률, 전자문서 및 전자거래 기본법, 통신비밀보호법 등 법령에서 일정기간 정보의 보관을 규정하는 경우는 아래와 같습니다. 모도는 이 기간 동안 법령의 규정에 따라 개인정보를 보관하며, 본 정보를 다른 목적으로는 절대 이용하지 않습니다.
                - 전자상거래 등에서 소비자 보호에 관한 법률
                계약 또는 청약철회 등에 관한 기록: 5년 보관
                대금결제 및 재화 등의 공급에 관한 기록: 5년 보관
                소비자의 불만 또는 분쟁처리에 관한 기록: 3년 보관
                - 전자문서 및 전자거래 기본법
                공인전자주소를 통한 전자문서 유통에 관한 기록 : 10년 보관
                - 통신비밀보호법
                로그인 기록: 3개월

                참고로 모도는 ‘개인정보 유효기간제’에 따라 1년간 서비스를 이용하지 않은 회원의 개인정보를 별도로 분리 보관하여 관리하고 있습니다.
            </p><br />

            <span style={{fontWeight: 'bolder',  marginLeft: '5px'}}>4. 개인정보 수집 및 이용 동의를 거부할 권리</span><br />
            <p style={{margin: '5px'}}>
                이용자는 개인정보의 수집 및 이용 동의를 거부할 권리가 있습니다. 회원가입 시 수집하는 최소한의 개인정보, 즉, 필수 항목에 대한 수집 및 이용 동의를 거부하실 경우, 회원가입이 어려울 수 있습니다.
            </p>


        </div>
    )

}

export default TermsContents;